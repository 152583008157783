import React, { ReactNode, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { useHistory } from 'react-router-dom';
import {
  LogoutOutlined,
  FileTextOutlined,
  UserOutlined,
  SolutionOutlined,
  QuestionOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';

import { useAuth } from '../../auth/AuthContext';
import expandedLogo from '../../img/logo.png';
import collapsedLogo from '../../img/logo-collapsed.png';

import css from './LeftPanel.module.css';

const App = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [isBroken, setIsBroken] = useState(false);
  const [siderWidth, setSiderWidth] = useState(260);

  const { Sider } = Layout;
  const location = useLocation();
  const history = useHistory();
  const { signOut } = useAuth();

  const onCollapse = (collapsed: boolean) => {
    setCollapsed(collapsed);
  };

  const handleLogOut = async () => {
    if (signOut) {
      await signOut();
      history.push('/sign-in');
    }
  };

  const selectedKeys = [];

  if (location.pathname === '/themes') {
    selectedKeys.push('themes');
  } else if (location.pathname === '/my-tests') {
    selectedKeys.push('my-tests');
  } else if (location.pathname === '/profile') {
    selectedKeys.push('profile');
  } else if (location.pathname === '/support') {
    selectedKeys.push('support');
  } else if (location.pathname === '/about') {
    selectedKeys.push('about');
  }

  const logoSrc = collapsed ? collapsedLogo : expandedLogo;

  const getItem = (
    key: string,
    label: ReactNode,
    icon: ReactNode,
    type?: string,
  ) => ({ key, label, icon, type });

  const handleClick = ({ key }: { key: string }) =>
    key === '/quit' ? handleLogOut() : history.push(key);

  const items = [
    getItem('/themes', 'Проверка знаний', <FileTextOutlined />),
    getItem('/my-tests', 'Мои тесты', <SolutionOutlined />),
    getItem('/profile', 'Личный кабинет', <UserOutlined />),
    getItem('/divider', null, null, 'divider'),
    getItem('/support', 'Помощь', <QuestionOutlined />),
    getItem('/about', 'О приложении', <InfoCircleOutlined />),
    getItem('/quit', 'Выйти', <LogoutOutlined />),
  ];

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
        width={siderWidth}
        className={css.Sider}
        breakpoint='xs'
        onBreakpoint={(broken) => {
          setIsBroken(broken);
          broken ? setSiderWidth(430) : setSiderWidth(260);
        }}
        collapsedWidth={collapsed && isBroken ? 0 : 70}
      >
        <div className={css.LogoContainer}>
          <img className={css.Logo} src={logoSrc} alt='Logo' />
        </div>
        <Menu
          theme='light'
          defaultSelectedKeys={['themes']}
          selectedKeys={selectedKeys}
          mode='inline'
          className={css.Menu}
          items={items}
          onClick={handleClick}
        />
      </Sider>
    </Layout>
  );
};

export default App;
