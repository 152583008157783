import { handleApiErrors } from './toast-error.util';

export const BASE_URL = process.env.REACT_APP_API_URL;

class ApiService {
  token;
  user;

  setAuthToken(token) {
    this.token = token;
  }

  setUser(user) {
    this.user = user;
  }

  async updateToken() {
    this.token = await this.user?.getIdToken(true);
  }

  async requestWithTokenUpdate(params) {
    const rawResponse = await this.request(params);
    const response = await rawResponse.json();

    params.retries = (params?.retries || 0) + 1;

    const rawResponseStatus = rawResponse.status;

    const { status: statusCode, result, error, message } = response;
    if (statusCode >= 400 || rawResponseStatus >= 400) {
      if (statusCode === 401 && params.retries === 1) {
        await this.updateToken();

        return await this.requestWithTokenUpdate(params);
      }
      const errors = [];

      errors.push(result?.error || error || message);

      handleApiErrors((errors.length && errors) || statusCode);
      return Promise.reject(result);
    }

    return Promise.resolve(result);
  }

  async request({ endpoint, method, body, query, headers = {} }) {
    const url = query
      ? `${BASE_URL}/${endpoint}${query}`
      : `${BASE_URL}/${endpoint}`;
    const _body = body && JSON.stringify(body);
    const auth = this.token ? { Authorization: `Bearer ${this.token}` } : {};
    const _headers = { ...auth, ...headers };

    return await fetch(url, {
      method,
      headers: _headers,
      body: _body,
    });
  }

  async get(endpoint, query = '') {
    return this.requestWithTokenUpdate({ endpoint, method: 'GET', query });
  }

  async post(endpoint, body) {
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.requestWithTokenUpdate({
      endpoint,
      method: 'POST',
      body,
      headers,
    });
  }

  async put(endpoint, body) {
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.requestWithTokenUpdate({
      endpoint,
      method: 'PUT',
      body,
      headers,
    });
  }

  async patch(endpoint, body) {
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.requestWithTokenUpdate({
      endpoint,
      method: 'PATCH',
      body,
      headers,
    });
  }
}

const api = new ApiService();

export default api;
