import React, { useState } from 'react';
import { Typography } from 'antd';

import useGetThemes from '../../hooks/useGetThemes';
import useOnQueryChange from '../../hooks/onQueryChange';
import PaymentModal from '../../components/PaymentModal/PaymentModal';
import PageWrapper from '../../components/MainPageWrapper/PageWrapper';
import KnowledgeCards from '../../components/KnowledgeCards/KnowledgeCards';
import KnowledgeSelectors from '../../components/KnowledgeSelectors/KnowledgeSelectors';
import { ITariffOptions, ITariff } from '../../interfaces/common';

import css from './KnowledgeCheck.module.css';

const KnowledgeCheck = () => {
  const [tariffs, setTariffs] = useState<ITariff[]>([]);
  const [tariff, setTariff] = useState<ITariffOptions>({});
  const [paymentData, setPaymentData] = useState<string[]>(['']);
  const [isDisplayPaymentModal, setIsDisplayPaymentModal] = useState(false);
  const { Title } = Typography;

  const togglePaymentModal = () => setIsDisplayPaymentModal((prev) => !prev);

  const {
    setSelectedCity,
    setSelectedCategory,
    selectedCity,
    selectedCategory,
  } = useOnQueryChange();

  const { themes, loading, setLoading } = useGetThemes({
    selectedCity,
    selectedCategory,
    setTariff,
    setTariffs,
  });

  return (
    <PageWrapper>
      <div className={css.SelectWrapper}>
        <Title level={2} style={{ color: '#1677ff' }}>
          Проверка знаний
        </Title>

        <KnowledgeSelectors
          selectedCity={selectedCity}
          setSelectedCity={setSelectedCity}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />

        <Title level={4} className={css.SpecTitle}>
          Специализация
        </Title>

        <KnowledgeCards
          themes={themes}
          tariff={tariff}
          tariffs={tariffs}
          isLoading={loading}
          setTariff={setTariff}
          setPaymentData={setPaymentData}
          togglePaymentModal={togglePaymentModal}
        />
      </div>

      <PaymentModal
        onClose={togglePaymentModal}
        isDisplay={isDisplayPaymentModal}
        setLoading={setLoading}
        paymentData={paymentData}
      />
    </PageWrapper>
  );
};

export default KnowledgeCheck;
