import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import Button from '../../components/Button/Buttons';
import PageContainer from '../../components/PageContainer/PageContainer';
import LoadingContainer from '../../components/LoadingContainer/LoadingContainer';
import { useAuth } from '../../auth/AuthContext';
import {
  IOrderStatus,
  statusFormatter,
} from '../PaymentSucceeded/PaymentSucceeded';
import api from '../../utils/api.util';
import {
  APP_URL,
  ENDPOINT,
  RETURN_TO_THE_MAIN_PAGE,
} from '../../constants/common-app.const';

import css from './PaymentFailed.module.css';

export const TEXT_DATA = {
  CONTACTS: 'Контакты службы поддержки:',
  ORDER_ID: 'ID Вашего заказа:',
  EXPIRES_AT: 'Подписка истекает:',
};

const PaymentFailed = () => {
  const [data, setData] = useState<IOrderStatus>();
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  const query = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );

  const orderId = query.get('orderId') || 'Нет данных';

  const getOrderStatus = useCallback(async () => {
    try {
      const response = await api.get(`${ENDPOINT.ORDERS}/${orderId}`);
      setData(response);
    } catch (e) {
      console.log(e);
    }
  }, [orderId]);

  useEffect(() => {
    if (orderId && isAuthenticated) {
      getOrderStatus();
    }
  }, [getOrderStatus, isAuthenticated, orderId]);

  const orderStatus = statusFormatter(data as IOrderStatus);

  return (
    <LoadingContainer isLoading={!data}>
      <PageContainer>
        <div className={css.Container}>
          <div className={css.Section}>
            <div className={css.Status}>{orderStatus}</div>
            <div>{`${TEXT_DATA.ORDER_ID} ${orderId}`}</div>
            <div className={css.Support}>
              <div>{TEXT_DATA.CONTACTS}</div>
              <div className={css.Contacts}>
                <p className={css.Info}>+375 (29) 842-55-55</p>
                <p className={css.Info}>+375 (29) 386-33-44</p>
                <p className={css.Info}>+375 (152) 60-92-24</p>
                <p className={css.Info}>email: info@rabt.by</p>
              </div>
            </div>
            <Link to={APP_URL.THEMES} className={css.Link}>
              <Button
                children={RETURN_TO_THE_MAIN_PAGE}
                className={css.Button}
              />
            </Link>
          </div>
        </div>
      </PageContainer>
    </LoadingContainer>
  );
};

export default PaymentFailed;
