import React, { FC } from 'react';
import { Input, Button, Form } from 'antd';
import InputMask from 'react-input-mask';

import ChangePassword from '../ChangePasswod/ChangePassword';
import { InputTypes } from '../Input/InputForm';

import { useAppContext } from '../../constants/app-context.conts';
import { IProfileForm } from '../../interfaces/common';
import toast from '../../utils/toast.util';
import api from '../../utils/api.util';
import { RESPONSE_CONTENT } from '../../utils/toast-error.util';
import {
  BUTTONS_TITLE,
  FORM_KEYS,
  FORM_LABELS,
  TITLE_PAGES,
} from '../../constants/form.const';

import css from './ProfileForm.module.css';

const ProfileForm: FC<IProfileForm> = ({ user }) => {
  const { setContextValue } = useAppContext();

  const onSubmitClick = async (values: any) => {
    await api
      .patch('profile', values)
      .then(() => api.get('profile'))
      .then((value) => setContextValue?.('profile', value));

    toast.showOnSuccess(RESPONSE_CONTENT.SUCCESS);
  };

  return (
    <div className={css.WrapperFormsContainer}>
      <Form onFinish={onSubmitClick} autoComplete='off' initialValues={user}>
        <fieldset className={css.FormFieldset}>
          <div className={css.Column}>
            <p className={css.ColumnTitle}>{TITLE_PAGES.PROFILE_TITLE}</p>
            <div className={css.InputWrapper}>
              <label className={css.InputLabel} htmlFor={FORM_KEYS.FIRST_NAME}>
                {FORM_LABELS.FIRST_NAME}
              </label>
              <Form.Item name={FORM_KEYS.FIRST_NAME} className={css.FormItem}>
                <Input
                  id={FORM_KEYS.FIRST_NAME}
                  className={css.FormInput}
                  type={InputTypes.text}
                />
              </Form.Item>
            </div>

            <div className={css.InputWrapper}>
              <label className={css.InputLabel} htmlFor={FORM_KEYS.LAST_NAME}>
                {FORM_LABELS.LAST_NAME}
              </label>
              <Form.Item name={FORM_KEYS.LAST_NAME} className={css.FormItem}>
                <Input
                  id={FORM_KEYS.LAST_NAME}
                  className={css.FormInput}
                  name={FORM_KEYS.LAST_NAME}
                  type={InputTypes.text}
                />
              </Form.Item>
            </div>

            <div className={css.InputWrapper}>
              <label className={css.InputLabel} htmlFor={FORM_KEYS.EMAIL}>
                {FORM_LABELS.EMAIL}
              </label>
              <Form.Item name={FORM_KEYS.EMAIL} className={css.FormItem}>
                <Input
                  id={FORM_KEYS.EMAIL}
                  className={css.FormInput}
                  name={FORM_KEYS.EMAIL}
                  type={InputTypes.text}
                  disabled={true}
                  autoComplete='off'
                />
              </Form.Item>
            </div>

            <div className={css.InputWrapper}>
              <label
                className={css.InputLabel}
                htmlFor={FORM_KEYS.PHONE_NUMBER}
              >
                {FORM_LABELS.PHONE_NUMBER}
              </label>
              <Form.Item name={FORM_KEYS.PHONE_NUMBER} className={css.FormItem}>
                <InputMask
                  id={FORM_KEYS.PHONE_NUMBER}
                  mask='+375 (99) 9999999'
                  maskChar='_'
                  placeholder='
                  +375 (XX) XXXXXXX'
                >
                  {(inputProps: any) => (
                    <input
                      {...inputProps}
                      id={FORM_KEYS.PHONE_NUMBER}
                      className={css.FormInput}
                      name={FORM_KEYS.PHONE_NUMBER}
                      type={InputTypes.text}
                    />
                  )}
                </InputMask>
              </Form.Item>
            </div>

            <div className={css.InputWrapper}>
              <label
                className={css.InputLabel}
                htmlFor={FORM_KEYS.COMPANY_NAME}
              >
                <span>{FORM_LABELS.COMPANY_NAME}</span>
              </label>
              <Form.Item name={FORM_KEYS.COMPANY_NAME} className={css.FormItem}>
                <Input
                  id={FORM_KEYS.COMPANY_NAME}
                  className={css.FormInput}
                  name={FORM_KEYS.COMPANY_NAME}
                  type={InputTypes.text}
                />
              </Form.Item>
            </div>

            <div className={css.InputWrapper}>
              <label
                className={css.InputLabel}
                htmlFor={FORM_KEYS.SPECIALIZATION}
              >
                <span>{FORM_LABELS.SPECIALIZATION}</span>
              </label>
              <Form.Item
                name={FORM_KEYS.SPECIALIZATION}
                className={css.FormItem}
              >
                <Input
                  id={FORM_KEYS.SPECIALIZATION}
                  className={css.FormInput}
                  name={FORM_KEYS.SPECIALIZATION}
                  type={InputTypes.text}
                />
              </Form.Item>
            </div>
          </div>

          <Button type='primary' htmlType='submit' className={css.Button}>
            {BUTTONS_TITLE.UPDATE_ACCOUNT}
          </Button>
        </fieldset>
      </Form>
      <div className={css.WrapperPasswordContainer}>
        <ChangePassword />
      </div>
    </div>
  );
};

export default ProfileForm;
