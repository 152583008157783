import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Input, Form } from 'antd';

import api from '../../utils/api.util';
import toast from '../../utils/toast.util';
import { APP_URL } from '../../constants/common-app.const';
import LoadingContainer from '../LoadingContainer/LoadingContainer';
import { RESPONSE_CONTENT } from '../../utils/toast-error.util';
import {
  BUTTONS_TITLE,
  DESCRIPTION_MESSAGES,
  FORM_KEYS,
  FORM_LABELS,
} from '../../constants/form.const';

import css from './PasswordRecoveryForm.module.css';

const PasswordRecoveryForm = () => {
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const history = useHistory();

  const handleSubmit = (values: any): void => {
    setLoading(true);

    api.post('password-recovery', { email: values.email }).then((value) => {
      if (value) {
        toast.showOnSuccess(RESPONSE_CONTENT.INSTRUCTION);
        setLoading(false);
        setIsDisable(true);
      }
    });
  };

  const handleBackClick = () => {
    history.push(APP_URL.SIGN_IN);
  };

  return (
    <LoadingContainer isLoading={loading}>
      <div className={css.RecoveryForm}>
        <p className={css.Title}>Восстановление пароля</p>
        <Form
          name='password-recovery'
          layout='vertical'
          onFinish={handleSubmit}
          className={css.FormContainer}
        >
          <Form.Item
            label={FORM_LABELS.ENTER_EMAIL}
            name={FORM_KEYS.EMAIL}
            rules={[
              {
                required: true,
                message: 'Пожалуйста, введите ваш email',
              },
            ]}
          >
            <Input type='email' autoComplete='on' className={css.FormInput} />
          </Form.Item>

          <div className={css.ButtonWrapper}>
            <Button
              children={BUTTONS_TITLE.SEND}
              type='primary'
              htmlType='submit'
              disabled={isDisable}
              className={css.Button}
            />
            <Button
              onClick={handleBackClick}
              children={BUTTONS_TITLE.BACK}
              type='default'
              disabled={isDisable}
              className={css.Button}
            />
          </div>

          <p className={css.Description}>
            {DESCRIPTION_MESSAGES.PASSWORD_UPDATE_INSTRUCTIONS}
          </p>
        </Form>
      </div>
    </LoadingContainer>
  );
};

export default PasswordRecoveryForm;
