import React, { FC } from 'react';
import { Modal as AntdModal } from 'antd';

interface IModalProps {
  display: boolean;
  onClose: () => void;
  children: React.ReactNode | React.ReactNode[];
  title?: string;
}

const Modal: FC<IModalProps> = ({ display, onClose, children, title }) => {
  return (
    <AntdModal
      centered
      footer={null}
      title={title}
      open={display}
      onCancel={() => onClose()}
    >
      {children}
    </AntdModal>
  );
};

export default Modal;
