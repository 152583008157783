import React from 'react';
import classnames from 'classnames';

import styles from './toast.module.css';

interface IProps {
  children: React.ReactNode;
  type: string;
  title: string;
}

const Toast: React.FC<IProps> = ({ children, type, title }) => {
  return (
    <div className={classnames(styles.container, styles[type])}>
      <div className={styles.contentWrapper}>
        <div className={styles.title}>{title}</div>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default React.memo(Toast);
