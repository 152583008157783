import { createContext, useContext } from 'react';
import {
  ICategories,
  ICities,
  IProfile,
  IQuestion,
} from '../interfaces/common';

export type ContextValue = any;

export type ContextValueSetter = (field: string, value: ContextValue) => void;

export interface IAppProps {
  questions: IQuestion[];
  cities: ICities[];
  profile: IProfile;
  categories: ICategories[];
  setContextValue?: ContextValueSetter;
}

export const appInitialState = {
  questions: [] as IQuestion[],
  cities: [] as ICities[],
  profile: {} as IProfile,
  categories: [] as ICategories[],
};

export const useAppContext = (): IAppProps => useContext(AppContext);

const AppContext = createContext(appInitialState);

export default AppContext;
