import { Link, useHistory, useLocation } from 'react-router-dom';
import React, { FC, useMemo, useState } from 'react';

import Button, { ButtonsType } from '../Button/Buttons';
import InputForm, { InputTypes } from '../Input/InputForm';

import {
  BUTTONS_TITLE,
  FORM_KEYS,
  FORM_LABELS,
  PATTERNS,
  TITLE_PAGES,
  VALIDATION,
} from '../../constants/form.const';

import api from '../../utils/api.util';
import toast from '../../utils/toast.util';
import { APP_URL } from '../../constants/common-app.const';
import { RESPONSE_CONTENT } from '../../utils/toast-error.util';

import css from './PasswordRecoveryCompleteForm.module.css';

interface IPasswordData {
  newUserPassword?: string;
  repeatNewUserPassword?: string;
}

const PasswordRecoveryCompleteForm: FC = () => {
  const [passwordData, setPasswordData] = useState<IPasswordData>({
    newUserPassword: '',
    repeatNewUserPassword: '',
  });

  const isDisable =
    (passwordData.repeatNewUserPassword || passwordData.newUserPassword) ===
      '' || passwordData.repeatNewUserPassword !== passwordData.newUserPassword;
  const history = useHistory();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setPasswordData({
      ...passwordData,
      [name]: value,
    });
  };

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  };

  const query = useQuery();
  const userId = query.get('userId');
  const recoveryHash = query.get('recoveryHash');

  const handleSubmit = async (event: React.SyntheticEvent): Promise<void> => {
    event.preventDefault();

    const newPasswordData = {
      userId,
      recoveryHash,
      password: passwordData.repeatNewUserPassword,
    };

    try {
      await api.post('auth/password-recovery/complete', newPasswordData);

      toast.showOnSuccess(RESPONSE_CONTENT.UPDATE_PASSWORD);

      history.push('/sign-in');
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={css.RecoveryForm}>
      <p className={css.Title}>{TITLE_PAGES.PASSWORD_RECOVERY}</p>
      <form
        className={css.FormContainer}
        onSubmit={handleSubmit}
        autoComplete='off'
      >
        <fieldset className={css.FormFieldset}>
          <InputForm
            id={FORM_KEYS.NEW_USER_PASSWORD}
            label={FORM_LABELS.ENTER_NEW_PASSWORD}
            className={css.FormInput}
            value={passwordData?.newUserPassword}
            name={FORM_KEYS.NEW_USER_PASSWORD}
            onChange={onChange}
            pattern={PATTERNS.PASSWORD}
            title={VALIDATION.PASSWORD_INVALID}
            type={InputTypes.password}
            htmlFor={FORM_KEYS.NEW_USER_PASSWORD}
          />
          <InputForm
            id={FORM_KEYS.REPEAT_NEW_USER_PASSWORD}
            label={FORM_LABELS.REPEAT_NEW_PASSWORD}
            className={css.FormInput}
            value={passwordData?.repeatNewUserPassword}
            name={FORM_KEYS.REPEAT_NEW_USER_PASSWORD}
            onChange={onChange}
            title={VALIDATION.PASSWORD_INVALID}
            pattern={PATTERNS.PASSWORD}
            type={InputTypes.password}
            htmlFor={FORM_KEYS.REPEAT_NEW_USER_PASSWORD}
          />
        </fieldset>
        <div className={css.ButtonWrapper}>
          <Button
            children={BUTTONS_TITLE.SEND}
            type={ButtonsType.submit}
            disabled={isDisable}
          />
          <Link className={css.Link} to={APP_URL.SIGN_IN}>
            Вернуться на страницу входа
          </Link>
        </div>
      </form>
    </div>
  );
};

export default PasswordRecoveryCompleteForm;
