import React, { FC, useState } from 'react';
import { Button, Form, Input } from 'antd';
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from 'firebase/auth';

import { useAuth } from '../../auth/AuthContext';
import toast from '../../utils/toast.util';
import {
  BUTTONS_TITLE,
  FORM_KEYS,
  FORM_LABELS,
  InitialStatePasswordForm,
  TITLE_PAGES,
} from '../../constants/form.const';
import { RESPONSE_CONTENT } from '../../utils/toast-error.util';

import css from './ChangePassword.module.css';

const ChangePassword: FC = () => {
  const [passwordData, setPasswordData] = useState(InitialStatePasswordForm);

  const { Password } = Input;
  const { user } = useAuth();

  const onChangePassword = async (values: any) => {
    setPasswordData({
      ...values,
    });

    if (values.userPassword == '') {
      toast.showOnFailure(RESPONSE_CONTENT.ERROR_CHANGE_PASSWORD);

      return;
    }

    if (
      values.newUserPassword != '' ||
      values.repeatNewUserPassword != '' ||
      values.newUserPassword !== values.repeatNewUserPassword
    ) {
      toast.showOnFailure(RESPONSE_CONTENT.ERROR_NEW_PASSWORD);

      return;
    }

    try {
      const credentials = EmailAuthProvider.credential(
        user?.email as string,
        passwordData.userPassword,
      );

      await reauthenticateWithCredential(user as any, credentials);
      await updatePassword(user as any, passwordData.newUserPassword);

      setPasswordData(InitialStatePasswordForm);

      toast.showOnSuccess(RESPONSE_CONTENT.SUCCESS_CHANGE_PASSWORD);
    } catch (err) {
      toast.showOnFailure(RESPONSE_CONTENT.ERROR_CHANGE_PASSWORD);
    }
  };

  return (
    <Form onFinish={onChangePassword} autoComplete='new-password'>
      <fieldset className={css.FormFieldset}>
        <p className={css.ColumnTitle}>{TITLE_PAGES.PROFILE_PASSWORD_TITLE}</p>

        <label className={css.InputLabel}>
          <span>{FORM_LABELS.ENTER_OLD_PASSWORD}</span>
        </label>
        <Form.Item name={FORM_KEYS.USER_PASSWORD} className={css.FormItem}>
          <Password
            type='password'
            autoComplete='new-password'
            className={css.FormInput}
          />
        </Form.Item>

        <label className={css.InputLabel}>
          <span>{FORM_LABELS.ENTER_NEW_PASSWORD}</span>
        </label>
        <Form.Item name={FORM_KEYS.NEW_USER_PASSWORD} className={css.FormItem}>
          <Password
            type='password'
            autoComplete='new-password'
            className={css.FormInput}
          />
        </Form.Item>

        <label className={css.InputLabel}>
          <span>{FORM_LABELS.REPEAT_NEW_PASSWORD}</span>
        </label>
        <Form.Item
          name={FORM_KEYS.REPEAT_NEW_USER_PASSWORD}
          className={css.FormItem}
        >
          <Password
            type='password'
            autoComplete='new-password'
            className={css.FormInput}
          />
        </Form.Item>

        <Button htmlType='submit' className={css.Button}>
          {BUTTONS_TITLE.CHANGE_PASSWORD}
        </Button>
      </fieldset>
    </Form>
  );
};

export default ChangePassword;
