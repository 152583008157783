import { useEffect, useState } from 'react';

import MyTests from '../../components/MyTests/MyTests';
import PageWrapper from '../../components/MainPageWrapper/PageWrapper';
import LoadingContainer from '../../components/LoadingContainer/LoadingContainer';
import { useAuth } from '../../auth/AuthContext';
import { ISession } from '../../interfaces/common';
import { ENDPOINT } from '../../constants/common-app.const';
import api from '../../utils/api.util';

const UserTests = () => {
  const { isAuthenticated } = useAuth();
  const [sessions, setSessions] = useState<ISession[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const getSession = async () => {
      const response = await api.get(ENDPOINT.SESSIONS);
      setSessions(response);
      setLoading(false);
    };

    if (isAuthenticated) {
      getSession();
    }
  }, [isAuthenticated]);

  return (
    <PageWrapper>
      <LoadingContainer isLoading={loading}>
        <MyTests sessions={sessions} />
      </LoadingContainer>
    </PageWrapper>
  );
};

export default UserTests;
