import RegistrationForm from '../../components/RegistrationForm/RegistrationForm';
import PageContainer from '../../components/PageContainer/PageContainer';
import logo from '../../img/logo.png';

import css from './RegistrationPage.module.css';

const Registration = () => (
  <PageContainer>
    <div className={css.Wrapper}>
      <img className={css.Logo} src={logo} alt='Logo' />
      <RegistrationForm />
    </div>
  </PageContainer>
);

export default Registration;
