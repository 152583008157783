import React from 'react';
import { YMInitializer } from 'react-yandex-metrika';

const YandexMetrika = () => {
    return (
        <YMInitializer accounts={[96727841]} options={{
            clickmap:true,
            trackLinks:true,
            accurateTrackBounce:true,
            webvisor:true
        }} />
    );
};

export default YandexMetrika;
