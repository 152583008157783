import { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import { useAuth } from './auth/AuthContext';
import YandexMetrika from './includes/YandexMetrika';
import About from './components/About/About';
import Profile from './pages/Profile/Profile';
import SingIn from './pages/SignIn/SignInPage';
import TestingPage from './pages/Test/TestingPage';
import Support from './components/Support/Support';
import UserTests from './pages/UserTests/UserTests';
import CompletedPage from './pages/Completed/CompletedPage';
import PrivateRoute from './auth/PrivateRoute/PrivateRoute';
import PaymentFailed from './pages/PaymentFailed/PaymentFailed';
import Registration from './pages/Registration/RegistrationPage';
import KnowledgeCheck from './pages/KnowledgeCheck/KnowledgeCheck';
import QuestionGroups from './pages/QuestionGroups/QuestionGroups';
import PasswordRecovery from './pages/PasswordRecovery/PasswordRecovery';
import PaymentSucceeded from './pages/PaymentSucceeded/PaymentSucceeded';
import PasswordRecoveryComplete from './pages/PasswordRecoveryComplete/PasswordRecoveryComplete';
import api from './utils/api.util';
import { ENDPOINT, APP_URL } from './constants/common-app.const';
import AppContext, {
  appInitialState,
  ContextValueSetter,
  IAppProps,
} from './constants/app-context.conts';

function App() {
  const { isAuthenticated } = useAuth();
  const [context, setContext] = useState<IAppProps>(appInitialState);

  const isLogged = localStorage.getItem('isAuthenticated');

  const setContextValue: ContextValueSetter = (field, value) => {
    setContext((prevstate) => ({
      ...prevstate,
      [field]: value,
    }));
  };

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    setContext((prevstate) => ({
      ...prevstate,
      setContextValue,
    }));

    api
      .get(ENDPOINT.CITIES)
      .then((value) => setContextValue?.('cities', value));
    api
      .get(ENDPOINT.PROFILE)
      .then((value) => setContextValue?.(ENDPOINT.PROFILE, value));
    api
      .get(ENDPOINT.CATEGORIES)
      .then((value) => setContextValue?.(ENDPOINT.CATEGORIES, value));
  }, [isAuthenticated]);

  return (
    <AppContext.Provider value={{ ...context }}>
      <YandexMetrika />
      <BrowserRouter>
        <Switch>
          <Route exact path='/'>
            <Redirect to={APP_URL.THEMES} />
          </Route>
          <Route exact path={APP_URL.SIGN_UP} component={Registration} />
          <Route
            exact
            path={APP_URL.SIGN_IN}
            render={() => (!isLogged ? <SingIn /> : <Redirect to='/' />)}
          />
          <Route
            exact
            path={APP_URL.PASSWORD_RECOVERY}
            component={PasswordRecovery}
          />
          <PrivateRoute
            path={APP_URL.PAYMENT_SUCCEEDED}
            component={PaymentSucceeded}
          />
          <PrivateRoute
            path={APP_URL.PAYMENT_FAILED}
            component={PaymentFailed}
          />
          <Route
            exact
            path={APP_URL.PASSWORD_RECOVERY_COMPLETE}
            component={PasswordRecoveryComplete}
          />
          <PrivateRoute
            exact
            path={APP_URL.THEMES}
            component={KnowledgeCheck}
          />
          <PrivateRoute exact path={APP_URL.PROFILE} component={Profile} />
          <PrivateRoute exact path={APP_URL.USER_TESTS} component={UserTests} />
          <PrivateRoute
            exact
            path={APP_URL.TESTING_PAGE}
            component={TestingPage}
          />
          <PrivateRoute exact path={APP_URL.SUPPORT} component={Support} />
          <PrivateRoute exact path={APP_URL.ABOUT} component={About} />
          <PrivateRoute
            exact
            path={APP_URL.QUESTION_GROUPS}
            component={QuestionGroups}
          />
          <PrivateRoute
            exact
            path={APP_URL.COMPLETED}
            component={CompletedPage}
          />
        </Switch>
      </BrowserRouter>
    </AppContext.Provider>
  );
}

export default App;
