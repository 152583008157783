import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, Typography } from 'antd';

import { IMyTests } from '../../interfaces/common';
import { DATA } from '../../constants/common-app.const';

import css from './MyTests.module.css';

const MyTests: FC<IMyTests> = ({ sessions }) => {
  const history = useHistory();
  const { Title } = Typography;

  const finishedTests = sessions?.filter((el) => el.isFinished);
  const unFinishedTests = sessions?.filter((el) => !el.isFinished);

  const handleClick = (questionGroup: string, sessionId: string): void => {
    history.push({
      pathname: `/question-group/${questionGroup}`,
      search: `sessionId=${sessionId}`,
    });
  };

  return (
    <div>
      {sessions.length ? (
        <>
          <Title level={2} style={{ color: '#1677ff' }}>
            Незавершенные тесты
          </Title>
          <div className={css.Wrapper}>
            {unFinishedTests.length ? (
              unFinishedTests.map((el) => {
                return (
                  <Card className={css.Container} key={el.id}>
                    <p className={css.GroupTitle}>{el.questionGroup.title}</p>
                    <div className={css.WrapperForInfo}>
                      <p>{el.questionGroup.theme.title}</p>
                      <p
                        className={css.Count}
                      >{`Выполнено ${el.answersCount} из ${el.questionsCount}`}</p>
                    </div>
                    <div className={css.ButtonWrapper}>
                      <Button
                        type='primary'
                        onClick={() => handleClick(el.questionGroupId, el.id)}
                      >
                        Продолжить
                      </Button>
                    </div>
                  </Card>
                );
              })
            ) : (
              <div className={css.NoTests}>{DATA.NO_UNFINISHED}</div>
            )}
          </div>
          <Title level={2} style={{ color: '#1677ff', marginTop: '3vw' }}>
            Завершенные тесты
          </Title>
          <div className={css.Wrapper}>
            {finishedTests.length ? (
              finishedTests.map((el) => {
                return (
                  <Card className={css.Container} key={el.id}>
                    <div>
                      <p className={css.GroupTitle}>{el.questionGroup.title}</p>
                      <p
                        className={css.Count}
                      >{`Правильных ответов ${el.correctAnswersCount} из ${el.questionsCount}`}</p>
                    </div>
                    <div className={css.ButtonWrapper}>
                      <Button
                        type='default'
                        onClick={() => handleClick(el.questionGroupId, el.id)}
                        className={css.FinishedButtonWrapper}
                      >
                        Посмотреть
                      </Button>
                    </div>
                  </Card>
                );
              })
            ) : (
              <div className={css.NoTests}>{DATA.NO_FINISHED}</div>
            )}
          </div>
        </>
      ) : (
        <div className={css.NoData}>{DATA.NOT_FOUND}</div>
      )}
    </div>
  );
};

export default MyTests;
