import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { Button } from 'antd';

import Modal from '../../components/Modal/Modal';
import InfoModal from '../../components/InfoModal/InfoModal';
import TestingForm from '../../components/TestingForm/TestingForm';
import { ReactComponent as Info } from '../../img/icons/information.svg';

import { APP_URL } from '../../constants/common-app.const';
import { BUTTONS_TITLE, QUIT_MODAL } from '../../constants/form.const';

import css from './Test.module.css';

const TestingPage = () => {
  const [isClosedInfoModal, setIsClosedInfoModal] = useState(false);
  const [isClosedQuitModal, setIsClosedQuitModal] = useState(false);

  const isShowInfoModal = () => setIsClosedInfoModal((prev) => !prev);
  const isShowQuitModal = () => setIsClosedQuitModal((prev) => !prev);

  const history = useHistory();

  const handleExitClick = () => {
    history.push(APP_URL.THEMES);
  };

  return (
    <div className={css.Container}>
      <div className={css.WrapperNavigation}>
        <div className={css.ControlContainer}>
          <Info className={css.Info} onClick={isShowInfoModal} />
        </div>
        <div className={css.ControlContainer}>
          <button className={css.Close} onClick={isShowQuitModal}>
            <span className={css.Quit}>{BUTTONS_TITLE.EXIT}</span>
          </button>
        </div>
      </div>

      <Modal
        onClose={isShowQuitModal}
        display={isClosedQuitModal}
        title={QUIT_MODAL.DESCRIPTION}
      >
        <div className={css.ModalContent}>
          <div className={css.ButtonContainer}>
            <Button
              className={css.ControlModalButton}
              onClick={isShowQuitModal}
              type='default'
            >
              <span>{BUTTONS_TITLE.CANCEL}</span>
            </Button>

            <Button
              onClick={handleExitClick}
              className={css.ControlModalButton}
              type='primary'
            >
              {BUTTONS_TITLE.EXIT}
            </Button>
          </div>
        </div>
      </Modal>
      <InfoModal onCloseModal={isShowInfoModal} isDisplay={isClosedInfoModal} />
      <TestingForm />
    </div>
  );
};

export default TestingPage;
