import React, { useEffect, useState } from 'react';

import Tests from '../../components/Tests/Tests';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../auth/AuthContext';
import { IQuestionGroup } from '../../interfaces/common';
import { ENDPOINT } from '../../constants/common-app.const';
import api from '../../utils/api.util';

interface ID {
  id: string;
}

const QuestionGroups = () => {
  const { id } = useParams<ID>();
  const { isAuthenticated } = useAuth();
  const [questionGroups, setQuestionGroups] = useState<IQuestionGroup[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (isAuthenticated) {
      const getQuestionGroups = async () => {
        const response = await api.get(`${ENDPOINT.QUESTION_GROUPS}/${id}`);

        setQuestionGroups(response);
        setLoading(false);
      };

      getQuestionGroups();
    }
  }, [isAuthenticated, id]);

  return <Tests questionGroups={questionGroups} loading={loading} />;
};

export default QuestionGroups;
