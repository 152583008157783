import React, { FC, SetStateAction } from 'react';
import { Select as AntdSelect, Typography } from 'antd';

import { ICategories } from '../../interfaces/common';
import { useAppContext } from '../../constants/app-context.conts';
import { convertToSelectOptions } from '../../helpers/convert-data-to-select-options';

import css from './KnowledgeSelectors.module.css';

interface IProps {
  selectedCity: string;
  selectedCategory: string;
  setSelectedCity: React.Dispatch<SetStateAction<string>>;
  setSelectedCategory: React.Dispatch<SetStateAction<string>>;
}

const KnowledgeSelectors: FC<IProps> = ({
  selectedCity,
  setSelectedCity,
  selectedCategory,
  setSelectedCategory,
}) => {
  const { Title } = Typography;
  const { categories, cities } = useAppContext();

  const categoryOptions = convertToSelectOptions(categories as ICategories[]);

  return (
    <div>
      <div className={css.Section}>
        <Title level={4} style={{ margin: '0' }}>
          Категория:
        </Title>

        <AntdSelect
          value={selectedCategory}
          className={css.Select}
          onChange={setSelectedCategory}
          placeholder={'Выберите категорию'}
        >
          <AntdSelect.Option
            value={''}
            key='allCategories'
            children='По всем'
          />

          {categoryOptions.map((option) => (
            <AntdSelect.Option value={option.value} key={option.value}>
              {option.label}
            </AntdSelect.Option>
          ))}
        </AntdSelect>
      </div>

      <div className={css.Section}>
        <Title level={4} className={css.CityTitle}>
          Город:
        </Title>

        <AntdSelect
          value={selectedCity}
          className={css.Select}
          onChange={setSelectedCity}
          placeholder='Выберите город'
        >
          <AntdSelect.Option value={''} key='allCities' children='По всем' />

          {cities?.map((city) => (
            <AntdSelect.Option key={city.id} value={city.id}>
              {city.title}
            </AntdSelect.Option>
          ))}
        </AntdSelect>
      </div>
    </div>
  );
};

export default KnowledgeSelectors;
