import { FC, useEffect, useState } from 'react';
import { User } from '@firebase/auth-types';

import api from '../utils/api.util';
import { auth } from '../utils/init-firebase';
import { AuthContext } from './AuthContext';

const AuthProvider: FC = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const initAuth = async () => {
      await auth.onAuthStateChanged((firebaseUser) =>
        setUser(firebaseUser as User),
      );

      const token = await user?.getIdToken(true);

      if (token) {
        api.setAuthToken(token);
        api.setUser(user);
        localStorage.setItem('isAuthenticated', 'true');
        setIsAuthenticated(true);
      }
    };

    initAuth();
  }, [user]);

  const signOut = async () => {
    try {
      await auth.signOut();

      localStorage.clear();

      setIsAuthenticated(false);
    } catch (error) {
      alert(error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        signOut,
        setUser,
        setIsAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
