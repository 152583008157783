import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const useOnQueryChange = () => {
  const [selectedCity, setSelectedCity] = useState<string>('');
  const [selectedCategory, setSelectedCategory] = useState<string>('');

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    if (selectedCity.trim().length > 0) {
      params.set('cityId', selectedCity);
    } else {
      params.delete('cityId');
    }

    if (selectedCategory.trim().length > 0) {
      params.set('categoryId', selectedCategory);
    } else {
      params.delete('categoryId');
    }

    history.replace({
      pathname: location.pathname,
      search: params.toString(),
    });
  }, [selectedCity, selectedCategory, history, location.search]);

  return {
    setSelectedCity,
    setSelectedCategory,
    selectedCity,
    selectedCategory,
  };
};

export default useOnQueryChange;
