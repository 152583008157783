import { Typography } from 'antd';

import ProfileForm from '../../components/Profile/ProfileForm';
import PageWrapper from '../../components/MainPageWrapper/PageWrapper';
import LoadingContainer from '../../components/LoadingContainer/LoadingContainer';
import { useAppContext } from '../../constants/app-context.conts';
import { DESCRIPTION_MESSAGES } from '../../constants/form.const';

import css from './Profile.module.css';

const Profile = () => {
  const { profile } = useAppContext();
  const { Title } = Typography;

  const convertDate = new Date(profile.createdAt).toLocaleDateString();
  const isLoading = profile.firstName;

  return (
    <PageWrapper>
      <Title level={2} style={{ color: '#1677ff' }}>
        Личный кабинет
      </Title>
      <LoadingContainer isLoading={!isLoading}>
        <div>
          <p className={css.UserName}>
            {profile.firstName} {profile.lastName}
          </p>
          <p
            className={css.ProfileInformation}
          >{`${DESCRIPTION_MESSAGES.USER_ID}${profile.id}`}</p>
          <p
            className={css.ProfileInformation}
          >{`${DESCRIPTION_MESSAGES.REGISTRATION_DATE}${convertDate}`}</p>
          <ProfileForm user={profile} />
        </div>
      </LoadingContainer>
    </PageWrapper>
  );
};

export default Profile;
