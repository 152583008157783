import { IChangePassword } from '../interfaces/common';

export const FORM_KEYS = {
  USER_NAME: 'userName',
  PASSWORD: 'password',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  EMAIL: 'email',
  PHONE_NUMBER: 'phoneNumber',
  COMPANY_NAME: 'companyName',
  SPECIALIZATION: 'specialization',
  USER_PASSWORD: 'userPassword',
  NEW_USER_PASSWORD: 'newUserPassword',
  REPEAT_NEW_USER_PASSWORD: 'repeatNewUserPassword',
  SELECT_DATE: 'selectDate',
  SET_LOCATION: 'setLocation',
};

export const FORM_LABELS = {
  FIRST_NAME: 'Имя',
  LAST_NAME: 'Фамилия',
  EMAIL: 'Адрес электронной почты',
  PHONE_NUMBER: 'Номер телефона',
  COMPANY_NAME: 'Название компании',
  SPECIALIZATION: 'Специальность',
  ENTER_PASSWORD: 'Введите пароль',
  ENTER_OLD_PASSWORD: 'Введите старый пароль',
  ENTER_NEW_PASSWORD: 'Введите новый пароль',
  REPEAT_NEW_PASSWORD: 'Повторите новый пароль',
  ENTER_EMAIL: 'Адрес электронной почты',
  YOUR_NAME: 'Ваше имя',
  SELECT_DATE: 'Примерная дата сдачи',
  SET_LOCATION: 'Откуда вы',
};

export const PLACEHOLDERS = {
  ENTER_YOU_NAME: 'Ведите ваше имя',
  ENTER_YOU_EMAIL: 'Введите ваш email',
  ENTER_PHONE_NUMBER: 'Ведите ваш номер телефона',
  SELECT_CITY: 'Выбери город или область',
  SELECT_DATE: 'Выберите дату и год сдачи',
};

export const PATTERNS = {
  PHONE_NUMBER: '^((375|+375)[0-9]{9})|((8029|8033|8044)[0-9]{7})$',
  PASSWORD: `^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[A-Za-z\\d?=.*@$!%*?&]{8,}$`,
  EMAIL: '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$',
};

export const InitialStatePasswordForm: IChangePassword = {
  userPassword: '',
  newUserPassword: '',
  repeatNewUserPassword: '',
};

export const BUTTONS_TITLE = {
  SEND: 'Отправить',
  ENTER: 'Войти',
  EXIT: 'Выйти',
  UPDATE_ACCOUNT: 'Обновить учетную запись',
  CHANGE_PASSWORD: 'Сменить пароль',
  CANCEL: 'Отмена',
  ENTER_TO_SIGN_IN: 'ВХОД',
  REGISTRATION: 'Продолжить',
  BACK: 'Назад',
};

export const DESCRIPTION_MESSAGES = {
  PASSWORD_UPDATE_INSTRUCTIONS:
    'Инструкция по восстановлению пароля придет Вам на почту.',
  FORGOT_PASSWORD: 'Забыли пароль?',
  USER_ID: 'Идентификатор пользователя: ',
  REGISTRATION_DATE: 'Дата регистрации: ',
  TEST_INFO_FIRST_MESSAGE:
    'Каждый вопрос содержит не менее 4 ответов, один из которых является правильным.',
  TEST_INFO_SECOND_MESSAGE:
    'При выборе неправильного ответа, мы подготовили для Вас возможность ознакомиться с извлечениями из нормативных правовых актов, касающихся заданного вопроса.',
  LOADING: 'Загрузка...',
  SING_UP: 'Регистрация',
  SUCCESS_MESSAGE:
    'Вам на почту отправлено письмо для подтверждения e-mail адреса.',
  DEFAULT_FLOW:
    'После продолжения, вы будете перенаправлены на страницу веб-тренажера, где вы сможете выбрать интересующую вас тему и пройти подготовку. Вам будет создан аккаунт, письмо с паролем будет отправлено на указанную вами почту.',
  DEMO_FLOW:
    'После продолжения, вы будете перенаправлены на страницу веб-тренажера, где вы сможете получить бесплатный демо-доступ к платформе и пройти подготовку к предстоящему экзамену. Вам будет создан аккаунт, письмо с паролем будет отправлено на указанную вами почту.',
};

export const TITLE_PAGES = {
  PROFILE_PASSWORD_TITLE: 'Ваш пароль',
  PROFILE_TITLE: 'Ваша учетная запись',
  PASSWORD_RECOVERY: 'Восстановление пароля',
};

export const VALIDATION = {
  PASSWORD_INVALID:
    'Пароль должен содержать строчные, прописные символы латинского алфавита, а так же минимум 1 цифру.',
};

export const QUIT_MODAL = {
  TITLE: 'Хотите выйти?',
  DESCRIPTION: 'Результаты будут сохранены в личном кабинете.',
};
