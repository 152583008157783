import PageContainer from '../../components/PageContainer/PageContainer';
import PasswordRecoveryCompleteForm from '../../components/PassRecoveryComplete/PasswordRecoveryCompleteForm';
import logo from '../../img/logo.png';

import css from './PasswordRecoveryComplete.module.css';

const PasswordRecoveryComplete = () => (
  <PageContainer>
    <div className={css.Wrapper}>
      <img className={css.Logo} src={logo} alt='Logo' />
      <PasswordRecoveryCompleteForm />
    </div>
  </PageContainer>
);

export default PasswordRecoveryComplete;
