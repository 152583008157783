import React, { FC } from 'react';

import Modal from '../Modal/Modal';

import { DESCRIPTION_MESSAGES } from '../../constants/form.const';

import css from './InfoModal.module.css';

export interface IModalRelocationForm {
  onCloseModal: () => void;
  isDisplay: boolean;
}

const InfoModal: FC<IModalRelocationForm> = ({ onCloseModal, isDisplay }) => (
  <Modal onClose={onCloseModal} display={isDisplay}>
    <div className={css.WrapperModalItems}>
      <div className={css.WrapperHeader}></div>
      <div className={css.InformTextModal}>
        <span>{DESCRIPTION_MESSAGES.TEST_INFO_FIRST_MESSAGE}</span>
        <p>{DESCRIPTION_MESSAGES.TEST_INFO_SECOND_MESSAGE}</p>
      </div>
    </div>
  </Modal>
);

export default InfoModal;
