import 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyCkWuwfNLzoVq2R15fS3ACIcnlDMR0oNXw',
  authDomain: 'rabt-exam.firebaseapp.com',
  projectId: 'rabt-exam',
  storageBucket: 'rabt-exam.appspot.com',
  messagingSenderId: '738680270455',
  appId: '1:738680270455:web:aff5fd9ba76023a3e4a072',
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
