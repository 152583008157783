import { Typography } from 'antd';

import PageWrapper from '../MainPageWrapper/PageWrapper';
import { aboutApp } from './About.const';

import css from './About.module.css';

const About = () => {
  const { Title } = Typography;

  return (
    <PageWrapper>
      <Title level={2} style={{ color: '#1677ff' }}>
        Веб-тренажёр для подготовки к сдаче экзаменов по охране труда
      </Title>
      <div
        className={css.Text}
        dangerouslySetInnerHTML={{ __html: aboutApp }}
      />
    </PageWrapper>
  );
};

export default About;
