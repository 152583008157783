import { toast, ToastPosition } from 'react-toastify';
import Toast from '../components/Toast/Toast';

export const TOAST_TYPES_MAP = {
  SUCCESS: 'success',
  ERROR: 'error',
};

export const TOAST_TITLE = {
  SUCCESS: 'готово',
  ERROR: 'ошибка',
  WARNING: 'внимание',
};

interface IInitialConfig {
  position: ToastPosition;
  newestOnTop: boolean;
  pauseOnVisibilityChange: boolean;
  draggable: boolean;
  hideProgressBar: boolean;
  autoClose: number;
  closeOnClick: boolean;
  rtl: boolean;
}

const initialConfig: IInitialConfig = {
  position: 'bottom-right',
  newestOnTop: true,
  pauseOnVisibilityChange: true,
  draggable: true,
  hideProgressBar: true,
  autoClose: 9000,
  closeOnClick: false,
  rtl: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  showOnSuccess(content: string): void {
    this.show(content, TOAST_TYPES_MAP.SUCCESS, TOAST_TITLE.SUCCESS);
  },
  showOnFailure(content: string): void {
    this.show(content, TOAST_TYPES_MAP.ERROR, TOAST_TITLE.ERROR);
  },

  show(content: string, type: string, title: string): void {
    toast(
      <Toast type={type} title={title}>
        {content}
      </Toast>,
      {
        ...initialConfig,
        toastId: content,
      },
    );
  },
};
