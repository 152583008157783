import React, { FC } from 'react';
import { Redirect, Route } from 'react-router-dom';

interface IPrivateRoute {
  component: any;
  path: string;
  exact?: boolean;
}

const PrivateRoute: FC<IPrivateRoute> = ({
  component: RouteComponent,
  path,
  exact,
}) => {
  const isAuthenticated = localStorage.getItem('isAuthenticated');

  return (
    <Route
      exact={exact}
      path={path}
      render={() =>
        isAuthenticated ? <RouteComponent /> : <Redirect to={'/sign-in'} />
      }
    />
  );
};

export default PrivateRoute;
