export const APP_URL = {
  THEMES: '/themes',
  IFRAME: '/sign-up-iframe',
  SIGN_IN: '/sign-in',
  PASSWORD_RECOVERY: '/password-recovery',
  PASSWORD_RECOVERY_COMPLETE: '/password-recovery-complete',
  PROFILE: '/profile',
  USER_TESTS: '/my-tests',
  TESTING_PAGE: '/question-group/:id',
  ABOUT: '/about',
  SUPPORT: '/support',
  QUESTION_GROUPS: '/themes/:id',
  QUESTION_GROUP: '/question-group',
  COMPLETED: '/completed/:id',
  PAYMENT_SUCCEEDED: '/payment-success',
  PAYMENT_FAILED: '/payment-failed',
  SIGN_UP: '/sign-up',
};

export const ENDPOINT = {
  CITIES: 'cities',
  PROFILE: 'profile',
  CATEGORIES: 'categories',
  THEMES: 'themes',
  TARIFFS: 'tariffs',
  QUESTION_GROUPS: 'question-groups',
  QUESTIONS: 'questions',
  SESSIONS: 'sessions',
  ORDERS: 'orders',
};

export const DATA = {
  NOT_FOUND: 'По Вашему запросу нет данных.',
  NO_FINISHED: 'У Вас нет завершенных тестов.',
  NO_UNFINISHED: 'У Вас нет незавершенных тестов.',
};

export const RETURN_TO_THE_MAIN_PAGE = 'Вернуться на главную страницу';
