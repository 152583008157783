import React, { FC } from 'react';
import { Button } from 'antd';

import Modal from '../Modal/Modal';
import api from '../../utils/api.util';
import { IOrderDetails } from '../../interfaces/common';
import { ENDPOINT } from '../../constants/common-app.const';

import css from './PaymentModal.module.css';

export interface IPaymentModal {
  onClose: () => void;
  isDisplay: boolean;
  tariffId?: string;
  themeId?: string;
  setLoading: (value: boolean) => void;
  paymentData: string[];
}

const PaymentModal: FC<IPaymentModal> = ({
  onClose,
  isDisplay,
  setLoading,
  paymentData,
}) => {
  const [themeId, tariffId] = paymentData;

  const handleClick = async (themeId: string, tariff: string) => {
    const dataForOrder = {
      themeId,
      tariff,
    };

    setLoading(true);

    try {
      const orderDetails: IOrderDetails = await api.post(
        ENDPOINT.ORDERS,
        dataForOrder,
      );

      setLoading(false);

      window.open(`${orderDetails.formUrl}`, '_blank');
      window.location.reload();
    } catch (err: any) {
      setLoading(false);

      window.location.reload();
    }
  };

  return (
    <Modal
      onClose={onClose}
      display={isDisplay}
      title={'Выберите способ оплаты'}
    >
      <div className={css.Container}>
        <div className={css.Wrapper}>
          <Button
            onClick={() => handleClick(themeId, tariffId)}
            className={css.Button}
            type='primary'
          >
            Оплата картой
          </Button>

          <Button
            className={css.Button}
            onClick={() => window.open('https://forms.amocrm.ru/rvzdlmv')}
          >
            Банковский перевод
          </Button>

          <Button className={css.Button} disabled>
            Оплата через ЕРИП
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PaymentModal;
