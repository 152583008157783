import React, { FC, useState } from 'react';

import { ReactComponent as ShowPassword } from './icons/showPassword.svg';
import { ReactComponent as HiddenPassword } from './icons/hidenPassword.svg';

import classnames from 'classnames';

import css from './InputForm.module.css';

interface IInputForm {
  id: string;
  label: string;
  className?: string;
  name: string;
  type: string;
  htmlFor?: string;
  pattern?: any;
  value?: string;
  title?: string;
  autoComplete?: string;
  required?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  placeholder?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export enum InputTypes {
  text = 'text',
  email = 'email',
  password = 'password',
}

const InputForm: FC<IInputForm> = ({
  type = InputTypes.text,
  id,
  className,
  name,
  label,
  onChange,
  htmlFor,
  pattern,
  title,
  required,
  autoComplete,
  defaultValue,
  placeholder,
  disabled,
  value,
}) => {
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  const classNames = classnames(css.FormInput, className);

  const isPasswordType = passwordShown ? InputTypes.text : InputTypes.password;
  const isShowPassword = type === InputTypes.password;

  return (
    <div className={css.InputWrapper}>
      <label className={css.InputLabel} htmlFor={htmlFor}>
        <span>{label}</span>
      </label>
      <div className={css.passWrapper}>
        <input
          id={id}
          className={classNames}
          onChange={onChange}
          name={name}
          type={type === InputTypes.password ? isPasswordType : type}
          pattern={pattern}
          title={title}
          value={value}
          required={required}
          placeholder={placeholder}
          disabled={disabled}
          defaultValue={defaultValue}
          autoComplete={autoComplete}
        />
        {isShowPassword && (
          <i className={css.cross} onClick={togglePasswordVisibility}>
            {passwordShown ? (
              <ShowPassword className={css.eyeIcon} />
            ) : (
              <HiddenPassword className={css.eyeIcon} />
            )}
          </i>
        )}
      </div>
    </div>
  );
};

export default InputForm;
