import React, { useState, useEffect } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import ruRU from 'antd/locale/ru_RU';
import InputMask from 'react-input-mask';
import { User } from '@firebase/auth-types';
import { useForm } from 'antd/lib/form/Form';
import { useHistory } from 'react-router-dom';
import { signInWithCustomToken } from 'firebase/auth';
import {
  ConfigProvider,
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Typography,
  Checkbox,
} from 'antd';

import api from '../../utils/api.util';
import toast from '../../utils/toast.util';
import { auth } from '../../utils/init-firebase';
import { useAuth } from '../../auth/AuthContext';
import LoadingContainer from '../LoadingContainer/LoadingContainer';
import {
  BUTTONS_TITLE,
  DESCRIPTION_MESSAGES,
  FORM_LABELS,
  PLACEHOLDERS,
} from '../../constants/form.const';

import css from './RegistrationForm.module.css';

dayjs.locale('ru');

interface City {
  id: string;
  title: string;
}

const RegistrationForm = () => {
  const [cities, setCities] = useState<City[]>([]);
  const [error, setError] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoader, setIsLoader] = useState<boolean>(true);

  const [form] = useForm();
  const history = useHistory();
  const { setUser, setIsAuthenticated } = useAuth();
  const { Title, Text, Paragraph } = Typography;

  const searchParams = new URLSearchParams(window.location.search);
  const flowType = searchParams.get('type');

  const descriptionText =
    flowType === 'demo'
      ? DESCRIPTION_MESSAGES.DEMO_FLOW
      : DESCRIPTION_MESSAGES.DEFAULT_FLOW;

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(
          'https://backoffice.bilet.rabt.by/items/cities',
        );
        const cityData = response.data.data;
        setCities(cityData);
      } catch (e) {
        toast.showOnFailure('Ошибка при загрузке данных о городах.');
      }
    })();
  }, []);

  const userNameValidator = (rule: any, value: string) => {
    if (value && value.trim() === '') {
      return Promise.reject('Пожалуйста, введите свое имя');
    }
    return Promise.resolve();
  };

  const phoneNumberValidator = (rule: any, value: string) => {
    if (value) {
      if (
        !/^(\s+)?\+375\s?\(?((17)|(29)|(33)|(44))\)?(\s+)?[0-9]{3}[0-9]{2}[0-9]{2}$/.test(
          value,
        )
      ) {
        return Promise.reject('Пожалуйста, введите свой номер телефона');
      }
    }
    return Promise.resolve();
  };

  const emailValidator = (rule: any, value: string) => {
    if (
      value &&
      !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(value)
    ) {
      return Promise.reject('Пожалуйста, введите правильный email');
    }
    return Promise.resolve();
  };

  const handleSubmit = async (values: any): Promise<void> => {
    setIsOpen(true);

    try {
      const body = {
        name: values.userName,
        phoneNumber: values.phoneNumber,
        email: values.email,
        locationId: values.locationId,
        examDate: values.examDate,
      };

      const customToken = await api.post('register', body);
      const { user } = await signInWithCustomToken(auth, customToken);
      const token = await user?.getIdToken(true);
      if (token) {
        api.setAuthToken(token);
        api.setUser(user);

        setUser && setUser(user as User);
        setIsAuthenticated && setIsAuthenticated(true);

        localStorage.setItem('isAuthenticated', 'true');

        history.push('/themes');
      } else {
        toast.showOnFailure('Ошибка аутентификации. Попробуйте снова.');
      }
    } catch (e: any) {
      setError(e?.error || 'Ошибка при регистрации!');
      setIsOpen(true);
      setIsLoader(false);
    }
  };

  return (
    <ConfigProvider
      locale={ruRU}
      theme={{
        components: {
          Input: {
            fontSize: 14,
            controlHeight: 45,
          },
          Select: {
            fontSize: 14,
            controlHeight: 45,
          },
          DatePicker: {
            controlHeight: 45,
            fontSize: 14,
            padding: 29,
          },
          Form: {
            fontSize: 12,
          },
          Typography: {
            titleMarginBottom: '1em',
            fontWeightStrong: 300,
          },
        },
      }}
    >
      <>
        {isOpen ? (
          <LoadingContainer isLoading={isLoader}>
            <div className={css.InfoMessageWrapper}>
              <h2 className={css.InfoMessage}>{error}</h2>
            </div>
          </LoadingContainer>
        ) : (
          <div className={css.RegistrationForm}>
            <Title level={5}>Укажите свои данные</Title>
            <Form
              form={form}
              name='form_item_path'
              layout='vertical'
              className={css.FormContainer}
              onFinish={handleSubmit}
              initialValues={{
                agreement: false, // Начальное значение для чекбокса
              }}
            >
              <Form.Item
                name='userName'
                label={FORM_LABELS.YOUR_NAME}
                rules={[
                  { required: true, message: 'Пожалуйста, введите свое имя' },
                  { validator: userNameValidator },
                ]}
                hasFeedback
              >
                <Input
                  className={css.FormInput}
                  placeholder={PLACEHOLDERS.ENTER_YOU_NAME}
                />
              </Form.Item>

              <Form.Item
                name='phoneNumber'
                label={FORM_LABELS.PHONE_NUMBER}
                rules={[
                  {
                    required: true,
                    message: 'Пожалуйста, введите свой номер телефона',
                  },
                  {
                    validator: phoneNumberValidator,
                  },
                ]}
                hasFeedback
              >
                <InputMask
                  mask='+375 (99) 9999999'
                  maskChar='_'
                  placeholder='+375 (XX) XXXXXXX'
                >
                  {(inputProps: any) => (
                    <Input className={css.FormInput} {...inputProps} />
                  )}
                </InputMask>
              </Form.Item>

              <Form.Item
                name='email'
                label={FORM_LABELS.EMAIL}
                rules={[
                  {
                    required: true,
                    message: 'Пожалуйста, введите свой email',
                  },
                  {
                    validator: emailValidator,
                  },
                ]}
                hasFeedback
              >
                <Input
                  className={css.FormInput}
                  placeholder={PLACEHOLDERS.ENTER_YOU_EMAIL}
                />
              </Form.Item>

              <Form.Item
                name='locationId'
                label={FORM_LABELS.SET_LOCATION}
                rules={[]}
                className={`${css.FormInput} ${css.SelectPadding}`}
                hasFeedback
              >
                <Select placeholder={PLACEHOLDERS.SELECT_CITY}>
                  {cities.map((city) => (
                    <Select.Option key={city.id} value={city.id}>
                      {city.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name='examDate'
                label={FORM_LABELS.SELECT_DATE}
                rules={[]}
                hasFeedback
              >
                <DatePicker
                  className={css.FormInput}
                  picker='month'
                  format='MM/YYYY'
                  placeholder={PLACEHOLDERS.SELECT_DATE}
                />
              </Form.Item>

              <Form.Item
                name='agreement'
                valuePropName='checked'
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error(
                              'Пожалуйста, подтвердите свое согласие с Правилами пользования сайтом и Политикой конфиденциальности',
                            ),
                          ),
                  },
                ]}
              >
                <Checkbox className={`${css.FormInput} ${css.CheckboxPadding}`}>
                  Я подтверждаю, что прочитал и соглашаюсь с{' '}
                  <a
                    href='https://rabt.by/terms/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Правилами пользования сайтом
                  </a>{' '}
                  и{' '}
                  <a
                    href='https://rabt.by/private-policy/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Политикой конфиденциальности.
                  </a>
                </Checkbox>
              </Form.Item>

              <Button
                type='primary'
                htmlType='submit'
                className={css.ButtonWrapper}
              >
                {BUTTONS_TITLE.REGISTRATION}
              </Button>

              <Text className={css.SignInPadding}>
                Уже есть аккаунт? <a href='/sign-in'>Войти</a>
              </Text>
            </Form>
            <Paragraph className={css.DescriptionText}>
              {descriptionText}
            </Paragraph>
          </div>
        )}
      </>
    </ConfigProvider>
  );
};

export default RegistrationForm;
