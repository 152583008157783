import React from 'react';
import { Typography } from 'antd';

import PageWrapper from '../MainPageWrapper/PageWrapper';

import css from './Support.module.css';

const Support = () => {
  const { Title } = Typography;

  return (
    <PageWrapper>
      <Title level={2} style={{ color: '#1677ff' }}>
        Помощь
      </Title>
      <div className={css.Text}>
        <p>
          Отправьте ваш запрос на e-mail: info@rabt.by или свяжитесь с нами по
          телефонам:
        </p>
        <ul>
          <li>+375 (29) 286-11-99,</li>
          <li>+375 (29) 842-55-55,</li>
          <li>+375 (152) 60-92-24.</li>
        </ul>
        <p> С вами свяжется наш специалист.</p>
        <p>
          Либо оставьте заявку в форме{' '}
          <a target={'_blank'} href={'https://forms.amocrm.ru/xzrdwv'}>
            по ссылке
          </a>
        </p>
      </div>
    </PageWrapper>
  );
};

export default Support;
