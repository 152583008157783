import React, { useCallback, useEffect, useState } from 'react';

import Completed from '../../components/Completed/Completed';
import LoadingContainer from '../../components/LoadingContainer/LoadingContainer';
import { useAuth } from '../../auth/AuthContext';
import { useLocation } from 'react-router-dom';
import { ISessionAnswers } from '../../interfaces/common';
import { ENDPOINT } from '../../constants/common-app.const';
import api from '../../utils/api.util';

const CompletedPage = () => {
  const [result, setResult] = useState<ISessionAnswers[]>();
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  const pathname = location.pathname.split('/');
  const sessionIdFromQuery = pathname[pathname.length - 1];

  const getResult = useCallback(async () => {
    const { sessionAnswers } = await api.get(
      `${ENDPOINT.SESSIONS}/${sessionIdFromQuery}`,
    );
    setResult(sessionAnswers);
  }, [sessionIdFromQuery]);

  useEffect(() => {
    if (isAuthenticated && !result) getResult();
  }, [getResult, isAuthenticated, result]);

  const questionsCount = result?.length as number;
  const rightAnswers = result?.filter((el) => el.isCorrect).length as number;

  const percent = Math.round((rightAnswers / questionsCount) * 100);

  return (
    <LoadingContainer isLoading={!result?.length}>
      <Completed
        percent={percent}
        questionsCount={questionsCount as number}
        right={rightAnswers as number}
      />
    </LoadingContainer>
  );
};

export default CompletedPage;
