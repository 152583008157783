import PageContainer from '../../components/PageContainer/PageContainer';
import SingInForm from '../../components/SignIn/SignInForm';
import logo from '../../img/logo.png';

import css from './SignIn.module.css';

const SingIn = () => (
  <PageContainer>
    <div className={css.Wrapper}>
      <img className={css.Logo} src={logo} alt='Logo' />
      <SingInForm />
    </div>
  </PageContainer>
);

export default SingIn;
