import { render } from 'react-dom';
import { ToastContainer } from 'react-toastify';

import App from './App';
import AuthProvider from './auth/AuthProvider';

import './index.css';

const root = document.createElement('div');
document.querySelector('body')?.appendChild(root);

render(
  <AuthProvider>
    <App />
    <ToastContainer />
  </AuthProvider>,
  root,
);
