import React, { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  signInWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
} from 'firebase/auth';
import { Button, Input, Form } from 'antd';

import { auth } from '../../utils/init-firebase';
import toast from '../../utils/toast.util';
import { RESPONSE_CONTENT } from '../../utils/toast-error.util';
import {
  BUTTONS_TITLE,
  DESCRIPTION_MESSAGES,
  FORM_KEYS,
  FORM_LABELS,
} from '../../constants/form.const';

import css from './SignInForm.module.css';

const SingInForm = () => {
  const history = useHistory();

  const handleLogin = useCallback(async (values) => {
    try {
      await setPersistence(auth, browserLocalPersistence);
      await signInWithEmailAndPassword(auth, values.email, values.password);

      history.push('/themes');
    } catch (error) {
      toast.showOnFailure(RESPONSE_CONTENT.WRONG_PASSWORD);
    }
  }, []);

  return (
    <div className={css.SingInForm}>
      <p className={css.Title}>Вход</p>
      <Form
        name='loginForm'
        onFinish={handleLogin}
        layout='vertical'
        className={css.FormContainer}
      >
        <Form.Item
          label={FORM_LABELS.ENTER_EMAIL}
          name={FORM_KEYS.EMAIL}
          rules={[
            {
              required: true,
              message: 'Пожалуйста, введите ваш email',
            },
          ]}
        >
          <Input type='email' autoComplete='on' className={css.FormInput} />
        </Form.Item>

        <Form.Item
          label={FORM_LABELS.ENTER_PASSWORD}
          name={FORM_KEYS.PASSWORD}
          rules={[
            {
              required: true,
              message: 'Пожалуйста, введите ваш пароль',
            },
          ]}
        >
          <Input type='password' autoComplete='on' className={css.FormInput} />
        </Form.Item>

        <div className={css.LinkWrapper}>
          <Link className={css.SignUpLink} to='/sign-up'>
            {DESCRIPTION_MESSAGES.SING_UP}
          </Link>

          <Link className={css.ForgetPass} to='/password-recovery'>
            {DESCRIPTION_MESSAGES.FORGOT_PASSWORD}
          </Link>
        </div>

        <div className={css.ButtonWrapper}>
          <Button
            type='primary'
            htmlType='submit'
            className={css.ButtonWrapper}
          >
            {BUTTONS_TITLE.ENTER}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default SingInForm;
