import React from 'react';

import Loading from '../Loading/Loading';

interface ILoadingContainer {
  isLoading: boolean;
  children: JSX.Element | JSX.Element[];
}

const LoadingContainer = ({
  isLoading,
  children,
}: ILoadingContainer): JSX.Element => {
  return isLoading ? <Loading /> : (children as JSX.Element);
};

export default LoadingContainer;
