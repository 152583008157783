import { IThemes } from '../interfaces/common';

const compareByDemoAccess = (a: IThemes, b: IThemes): number => {
  if (a.demoAccessEnabled === b.demoAccessEnabled) {
    return 0;
  }
  return a.demoAccessEnabled ? -1 : 1;
};

export default compareByDemoAccess;
