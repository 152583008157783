import { useCallback, useEffect, useState } from 'react';

import api from '../utils/api.util';
import toast from '../utils/toast.util';
import { useAuth } from '../auth/AuthContext';
import { ENDPOINT } from '../constants/common-app.const';
import { ITariff, ITariffOptions, IThemes } from '../interfaces/common';

const useGetThemes = ({
  selectedCity = '',
  selectedCategory = '',
  setTariff,
  setTariffs,
}: {
  selectedCity: string | null;
  selectedCategory: string | null;
  setTariff: Function;
  setTariffs: Function;
}) => {
  const [themes, setThemes] = useState<IThemes[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const { isAuthenticated } = useAuth();

  const getThemes = useCallback(async () => {
    const queryParams: string[] = [];

    if (selectedCity) {
      queryParams.push(`cityId=${selectedCity}`);
    }

    if (selectedCategory) {
      queryParams.push(`categoryId=${selectedCategory}`);
    }

    try {
      setLoading(true);

      const [themesResponse, tariffsResponse] = await Promise.all([
        api.get(`${ENDPOINT.THEMES}?${queryParams.join('&')}`),
        api.get(ENDPOINT.TARIFFS),
      ]);

      const formattedTariffs = tariffsResponse
        .filter((tariff: ITariff) => !tariff.themeId)
        .sort((a: ITariff, b: ITariff) => (a.cost > b.cost ? 1 : -1))
        .map((el: ITariff) => ({ label: el.title, value: el.id }));

      setThemes(themesResponse);
      setTariffs(formattedTariffs);

      themesResponse?.forEach((theme: IThemes) => {
        setTariff((prevState: ITariffOptions) => ({
          ...prevState,
          [theme.id]: theme?.tariffs?.length
            ? {
                label: theme.tariffs[0].title,
                value: theme.tariffs[0].id,
              }
            : { label: tariffsResponse[0].title, value: tariffsResponse[0].id },
        }));
      });

      setLoading(false);
    } catch (error) {
      toast.showOnFailure('Ошибка загрузки данных с сервера');

      setLoading(false);
    }
  }, [selectedCity, selectedCategory]);

  useEffect(() => {
    if (isAuthenticated) {
      getThemes();
    }
  }, [isAuthenticated, getThemes]);

  return { themes, loading, setLoading };
};

export default useGetThemes;
