import { FC } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as Cross } from '../../img/icons/cross.svg';
import { ReactComponent as CompletedImage } from './icon/completed.svg';

import css from './Completed.module.css';

interface ICompleted {
  percent: number;
  right: number;
  questionsCount: number;
}

const Completed: FC<ICompleted> = ({ percent, right, questionsCount }) => (
  <div className={css.Container}>
    <Link to='/' className={css.Link}>
      <button className={css.Close}>
        <Cross className={css.BigCross} />
        <span className={css.Quit}>Выйти</span>
      </button>
    </Link>
    <div className={css.CompletedContainer}>
      <h1 className={css.Title}>ТЕСТ ЗАВЕРШЕН</h1>
      <div className={css.CompletedWrapper}>
        <CompletedImage className={css.CompletedImage} />
        <div className={css.PercentsWrapper}>
          <div className={css.Percents}>{`${percent} %`}</div>
          <div
            className={css.RightAnswers}
          >{`Правильных ответов: ${right}`}</div>
          <div
            className={css.QuestionsCount}
          >{`Всего вопросов: ${questionsCount}`}</div>
        </div>
      </div>
    </div>
  </div>
);

export default Completed;
