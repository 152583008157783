import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import cx from 'classnames';

import Button from '../../components/Button/Buttons';
import PageContainer from '../../components/PageContainer/PageContainer';
import LoadingContainer from '../../components/LoadingContainer/LoadingContainer';
import { useAuth } from '../../auth/AuthContext';
import {
  APP_URL,
  ENDPOINT,
  RETURN_TO_THE_MAIN_PAGE,
} from '../../constants/common-app.const';
import { TEXT_DATA } from '../PaymentFailed/PaymentFailed';
import api from '../../utils/api.util';

import css from './PaymentSucceeded.module.css';

export interface IOrderStatus {
  status: string;
  expireAt: string;
}

export const statusFormatter = (data: IOrderStatus) => {
  switch (data?.status) {
    case 'COMPLETED':
      return 'УСПЕШНО';
    case 'CANCELLED':
      return 'ОТМЕНЕН';
    case 'RETURNED':
      return 'ВОЗВРАТ';
    case 'PENDING':
      return 'ОЖИДАНИЕ';
    case 'REJECTED':
      return 'ПЛАТЕЖ ОТКЛОНЕН';
    case 'CREATED':
      return 'СОЗДАН';

    default:
      return 'ОШИБКА';
  }
};

const PaymentSucceeded = () => {
  const [data, setData] = useState<IOrderStatus>();
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  const query = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );

  const orderId = query.get('orderId');

  const completeOrdering = useCallback(async () => {
    try {
      const response = await api.get(`${ENDPOINT.ORDERS}/${orderId}`);
      setData(response);
    } catch (e) {
      console.log(e);
    }
  }, [orderId]);

  useEffect(() => {
    if (orderId && isAuthenticated) {
      completeOrdering();
    }
  }, [completeOrdering, isAuthenticated, orderId]);

  const orderStatus = statusFormatter(data as IOrderStatus);

  const expireDate = new Date(data?.expireAt as string).toLocaleString('ru', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });

  const statusClassName = cx(css.Status, {
    [css.Completed]: orderStatus === 'УСПЕШНО',
  });

  return (
    <LoadingContainer isLoading={!data}>
      <PageContainer>
        <div className={css.Container}>
          <div className={css.Section}>
            <div className={statusClassName}>{orderStatus}</div>
            <div>{`${TEXT_DATA.ORDER_ID} ${orderId}`}</div>
            <div
              className={css.ExpireDate}
            >{`${TEXT_DATA.EXPIRES_AT} ${expireDate}`}</div>
            <Link to={APP_URL.THEMES} className={css.Link}>
              <Button
                children={RETURN_TO_THE_MAIN_PAGE}
                className={css.Button}
              />
            </Link>
          </div>
        </div>
      </PageContainer>
    </LoadingContainer>
  );
};

export default PaymentSucceeded;
