import toast from './toast.util';

export type Errors = string[] | number;

export const RESPONSE_CONTENT = {
  SUCCESS: 'Ваши данные успешно обновлены.',
  SUCCESS_CHANGE_PASSWORD: 'Ваш пароль успешно обновлен.',
  ERROR_CHANGE_PASSWORD: 'Введен не верный старый пароль',
  ERROR_NEW_PASSWORD: 'Новые пароли не совпадают',
  UPDATE_PASSWORD: 'Ваш пароль успешно обновлен',
  ERROR: 'Сервер ответил со статусом: ',
  REGISTRATION:
    'Вы успешно зарегистрированы! Данные для входа отправлены на Вашу почту',
  INSTRUCTION:
    'На указанный email было отправлено письмо со ссылкой на восстановление пароля',
  WRONG_PASSWORD: 'Неверный email или пароль',
};

export const handleApiErrors = (errors: Errors): void => {
  if (typeof errors === 'number') {
    toast.showOnFailure(RESPONSE_CONTENT.ERROR + errors);
  } else {
    errors.forEach((err) => {
      toast.showOnFailure(`${err}`);
    });
  }
};
