import { FC } from 'react';
import { Link } from 'react-router-dom';

import OneTest from '../OneTestBlock/OneTest';
import PageWrapper from '../MainPageWrapper/PageWrapper';
import LoadingContainer from '../LoadingContainer/LoadingContainer';
import { IQuestionGroup, ITests } from '../../interfaces/common';
import { DATA } from '../../constants/common-app.const';
import { Typography } from 'antd';

import { ReactComponent as Info } from '../../img/icons/information.svg';

import css from './Tests.module.css';

const Tests: FC<ITests> = ({ questionGroups, loading }) => {
  const activeSubscribe = questionGroups.map((e) => e.theme.userSubscriptions);

  const { Title } = Typography;

  function compareByDemoAccess(a: IQuestionGroup, b: IQuestionGroup): number {
    if (a.demoAccessEnabled === b.demoAccessEnabled) {
      return 0;
    }
    return a.demoAccessEnabled ? -1 : 1;
  }

  const sortedGroups = questionGroups.sort((a, b) => {
    const firstTitle = parseInt(a.title.replace(/[^\d]/g, ''));
    const secondTitle = parseInt(b.title.replace(/[^\d]/g, ''));

    return firstTitle > secondTitle ? 1 : -1;
  });

  return (
    <PageWrapper>
      <LoadingContainer isLoading={loading}>
        <div className={css.TitleContainer}>
          <Title className={css.Title}>Тесты</Title>
          {!activeSubscribe.length ? (
            <div className={css.Information}>
              <Info />
              <p className={css.TextInfo}>
                Чтобы начать проходить тесты, вам необходимо произвести оплату.
                <br />
                Для этого перейдите на страницу{' '}
                <Link to='/'>Проверка знаний</Link>.
              </p>
            </div>
          ) : null}
        </div>
        <div className={css.TestsWrapper}>
          <span className={css.Specialization}>
            {questionGroups[0]?.theme?.title}
          </span>
          <div className={css.TestsContainer}>
            {questionGroups?.length > 0 ? (
              sortedGroups
                .sort(compareByDemoAccess)
                .map((el: IQuestionGroup) => <OneTest test={el} key={el.id} />)
            ) : (
              <div>{DATA.NOT_FOUND}</div>
            )}
          </div>
        </div>
      </LoadingContainer>
    </PageWrapper>
  );
};
export default Tests;
