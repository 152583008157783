import PageContainer from '../../components/PageContainer/PageContainer';
import PasswordRecoveryForm from '../../components/PassRecovery/PasswordRecoveryForm';
import logo from '../../img/logo.png';

import css from './PasswordRecovery.module.css';

const PasswordRecovery = () => (
  <PageContainer>
    <div className={css.Wrapper}>
      <img className={css.Logo} src={logo} alt='Logo' />
      <PasswordRecoveryForm />
    </div>
  </PageContainer>
);

export default PasswordRecovery;
