import React, { FC } from 'react';

import { ReactComponent as Tail } from './tail-spin.svg';
import { DESCRIPTION_MESSAGES } from '../../constants/form.const';

import css from './Loading.module.css';

const Loading: FC = () => {
  return (
    <div className={css.WrapperLoading}>
      <div className={css.Loading}>
        <Tail className={css.Arrow} />
        <span className={css.Title}>{DESCRIPTION_MESSAGES.LOADING}</span>
      </div>
    </div>
  );
};

export default Loading;
