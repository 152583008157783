import { createContext, useContext } from 'react';
import { User } from '@firebase/auth-types';

export type AuthProps = {
  user: User | null;
  isAuthenticated: boolean;
  signOut: () => Promise<void>;
  setUser: (user: User) => void;
  setIsAuthenticated: (value: boolean) => void;
};

export const AuthContext = createContext<Partial<AuthProps>>({
  user: null,
  isAuthenticated: false,
  setUser: () => {},
  setIsAuthenticated: () => {},
  signOut: async () => {},
});

export const useAuth = (): Partial<AuthProps> => useContext(AuthContext);
